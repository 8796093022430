import React, { useState } from 'react';
import { Link } from 'react-scroll'
import Headroom from 'react-headroom';
import './Navbar.css';

const Navbar = () => {
	const [activeLink, setActiveLink] = useState();

	return (
		<Headroom style={{ zIndex: 2 }}>
			<nav className="navbarContainer">
				<div className="logoSection">
					<img className='logoImg' src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="ROBOTICITMS" />
					<h1 className="logoText">ETOS</h1>
				</div>
				<ul className="navLinks">
					<li className="navItem">
						<Link
							to="hero"
							smooth={true}
							offset={-110}
							duration={500}
							className={activeLink === 'about' ? 'active' : ''}
							onClick={() => setActiveLink('about')}
						>About
						</Link>
					</li>
					<li className="navItem">
						<Link
							to="resources"
							smooth={true}
							offset={-110}
							duration={500}
							className={activeLink === 'Resources' ? 'active' : ''}
							onClick={() => setActiveLink('Resources')}
						>Resources
						</Link>
					</li>
					<li className="navItem">
						<Link
							to="products"
							smooth={true}
							offset={-110}
							duration={500}
							className={activeLink === 'Products' ? 'active' : ''}
							onClick={() => setActiveLink('Products')}
						>Products
						</Link>
					</li>
					<li className="navItem">
						<Link
							to="contact"
							smooth={true}
							offset={-110}
							duration={500}
							className={activeLink === 'Contact' ? 'active' : ''}
							onClick={() => setActiveLink('Contact')}
						>Contact
						</Link>
					</li>
				</ul>
			</nav>
		</Headroom>
	);
};

export default Navbar;