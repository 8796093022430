import React, { useState } from 'react';
import './ContactUs.css';
import { message } from 'antd';

const ContactUs = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('Form submitted:', formData);
		setFormData({ name: '', email: '', message: '' });
		message.success("Thank you for reaching out! We'll get back to you shortly.")
	};

	return (
		<div className="contactUsContainer" id='contact'>
			<h2 className="contactUsTitle">Get in Touch</h2>
			<p className="contactUsDescription">
				We’d love to hear from you! Fill out the form below and we’ll get back to you shortly.
			</p>
			<form className="contactForm" onSubmit={handleSubmit}>
				<input
					type="text"
					name="name"
					placeholder="Your Name"
					value={formData.name}
					onChange={handleChange}
					required
					className="formInput"
				/>
				<input
					type="email"
					name="email"
					placeholder="Your Email"
					value={formData.email}
					onChange={handleChange}
					required
					className="formInput"
				/>
				<textarea
					name="message"
					placeholder="Your Message"
					value={formData.message}
					onChange={handleChange}
					required
					className="formTextarea"
				/>
				<button type="submit" className="submitButton">Send Message</button>
			</form>
		</div>
	);
};

export default ContactUs;
