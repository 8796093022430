import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Hero from '../components/Hero/Hero'
import Resources from '../components/Resources/Resources'
import Products from '../components/Products/Products'
import ContactUs from '../components/ContactUs/ContactUs'
import Footer from '../components/Footer/Footer'

const Landing = () => {
	return (
		<>
			<Navbar />
			<Hero />
			<Resources />
			<Products />
			<ContactUs />
			<Footer />
		</>
	)
}

export default Landing