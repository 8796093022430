import React from 'react';
import './Hero.css';

const Hero = () => {
	return (
		<section className="heroContainer" id='hero'>
			<div className="heroContent">
				<h1 className="heroQuote">"We bring robots to life."</h1>
				<p className="heroDescription">
					Robotics is the frontier of technology, pushing the boundaries of automation, artificial intelligence,
					and human ingenuity to create machines that improve our lives.
				</p>
			</div>
		</section>
	);
};

export default Hero;
