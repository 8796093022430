import React, { useRef } from 'react';
import carouselData from './carouselData.json';
import './Resources.css';

const Resources = () => {
	const carouselRef = useRef(null);

	// const scrollLeft = () => {
	// 	if (carouselRef.current) {
	// 		carouselRef.current.scrollBy({ left: -200, behavior: 'smooth' });
	// 	}
	// };

	// const scrollRight = () => {
	// 	if (carouselRef.current) {
	// 		carouselRef.current.scrollBy({ left: 200, behavior: 'smooth' });
	// 	}
	// };

	return (
		<div className="sparePartsContainer" id='resources'>
			<h2 className="sparePartsTitle">Resources for Robotics</h2>
			<div className="carouselContainer">
				{/* <button className="carouselArrow left" onClick={scrollLeft}>&lt;</button> */}
				<div className="carouselTrack" ref={carouselRef}>
					{carouselData.map((item, index) => (
						<div key={index} className="carouselCard">
							<img src={process.env.PUBLIC_URL + item.imageUrl} alt={item.title} className="carouselImage" />
							<h3 className="carouselTitle">{item.title}</h3>
							<p className="carouselDescription">{item.description}</p>
						</div>
					))}
				</div>
				{/* <button className="carouselArrow right" onClick={scrollRight}>&gt;</button> */}
			</div>
		</div>
	);
};

export default Resources;
