import React from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<footer className="footerContainer">
			<div className="footerSections">
				<div className="footerSection address">
					<h4 className="footerTitle">ADDRESS</h4>
					<p className="footerAddress">
						66, TP Road, Nungambakkam,<br />Chennai - 600034, Tamil Nadu
					</p>
					<p className="footerEmail">
						<b>Email: </b><u><a href="mailto:admin@etos.net.in">admin@etos.net.in</a></u>
					</p>
				</div>
				<div className="footerSection">
					<h4 className="footerTitle">COMPANY</h4>
					<ul className="footerList">
						<li>Terms of Use</li>
						<li>Privacy Policy</li>
						<li>Blog</li>
						<li>Sitemap</li>
					</ul>
				</div>
				<div className="footerSection newsletter">
					<h4 className="footerTitle">NEWSLETTER</h4>
					<input type="email" className="newsletterInput" placeholder="Your email address" />
					<button className="subscribeButton">SUBSCRIBE</button>
				</div>
			</div>
			<div className="footerCopyright">
				<p>Copyright © 2024. ETOS</p>
			</div>
		</footer>
	);
};

export default Footer;
