import React, { useState } from "react";
import "./Products.css";

const cardData = [
	{
		img: "https://res.cloudinary.com/drqiw6wyl/image/upload/v1728538476/Screenshot_2024-10-10_105742_v49a04.png",
		title: "Six-Degree-Of-Freedom Machine",
		description: `The Six-Degree-Of-Freedom Machine is commonly used in education for demonstrations, DIY projects, and maker education. 
    It features six servo motors that control movements such as grasping and carrying actions in all directions. Constructed with a 
    2mm thick aluminum plate, the structure is stable and durable, ideal for educational purposes.`,
	},
	{
		img: "https://res.cloudinary.com/drqiw6wyl/image/upload/v1728538476/Screenshot_2024-10-10_105923_xfatey.png",
		title: "DIY Robotic Prosthetic Hand Kit",
		description: `A DIY kit to build a robotic prosthetic hand, featuring high-quality 3D-printed parts and an SG90 servo motor for thumb movement. 
    Designed for robotics enthusiasts, educators, and makers exploring 3D printing and robotics.`,
	},
	{
		img: "https://res.cloudinary.com/drqiw6wyl/image/upload/v1728538477/Screenshot_2024-10-10_105935_ebeaqa.png",
		title: "Arduino IDE-based Uno Board",
		description: `Uno Board SMD based on Arduino IDE. Specifications include:
    - Operating Voltage: 5V
    - Input Voltage (recommended): 7-12V
    - Flash Memory: 32KB (ATmega328P), with 0.5KB used by the bootloader
    - EEPROM: 1KB (ATmega328P)
    - Microcontroller Unit: ATmega328P`,
	},
	{
		img: "https://res.cloudinary.com/drqiw6wyl/image/upload/v1728538476/Screenshot_2024-10-10_105947_ktzggb.png",
		title: "High-Speed 3D Printer",
		description: `High-speed 3D printer offering up to 250mm/s printing speed, with a recommended speed of 150mm/s for balanced quality and efficiency.
    Features a 60W extrusion system, a 7000 rpm cooling fan, and Marlin firmware for improved stability and detail in prints.`,
	},
	{
		img: "https://res.cloudinary.com/drqiw6wyl/image/upload/v1728538476/Screenshot_2024-10-10_105959_mz87e7.png",
		title: "SCR Triac Electric Voltage Regulator",
		description: `SCR Triac Electric Voltage Regulator suitable for controlling temperature, motor speed, and dimming lights. 
    It is user-friendly and versatile, useful for appliances like electric furnaces, heaters, and small motors, offering 
    compact and reliable control for household applications.`,
	},
	{
		img: "https://res.cloudinary.com/drqiw6wyl/image/upload/v1728538477/Screenshot_2024-10-10_110010_ipwe3x.png",
		title: "Starter Sensors Kit",
		description: `Comprehensive starter sensors kit with 30+ DIY projects and video tutorials. Ideal for children learning programming, 
    circuit connection, and component use. Includes live online support and an accredited STEM certificate upon completion of all projects.`,
	},
];

const Products = () => {
	const [selectedCard, setSelectedCard] = useState(null);

	const handleLearnMoreClick = (index) => {
		setSelectedCard(index);
	};

	const closeModal = () => {
		setSelectedCard(null);
	};

	return (
		<div className="RobotProductsContainer" id="products">
			<h2 className="sparePartsTitle">Our Products</h2>
			<div className="RobotProducts-card-container">
				{cardData.map((card, index) => (
					<div key={index} className="RobotProducts-card">
						<div className="RobotProducts-card-inner">
							<div className="RobotProducts-card-front">
								<img src={card.img} alt={card.title} />
								<h3>{card.title}</h3>
							</div>
							<div className="RobotProducts-card-back">
								<button onClick={() => handleLearnMoreClick(index)}>Learn More</button>
							</div>
						</div>
					</div>
				))}
				{selectedCard !== null && (
					<div className="RobotProducts-modal-overlay" onClick={closeModal}>
						<div className="RobotProducts-modal animated" onClick={(e) => e.stopPropagation()}>
							<h2>{cardData[selectedCard].title}</h2>
							<p>{cardData[selectedCard].description}</p>
							<button onClick={closeModal}>Close</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Products;